import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Container } from "react-awesome-styled-grid"
import siteConfig from "../../data/siteConfig"
import loadable from "@loadable/component"
import Hero from "../components/hero"
import Wrapper from "../components/wrapper"
import { GlobalStateContext } from "../components/context"
import SEO from "../components/SEO"

const Layout = loadable(() => import("../components/layout"))

const StyledWrapper = styled(Wrapper)`
  border: none;
  display: flex;
  flex-direction: column;
  top: -80px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.wrapperBackground};
  color: ${({ theme }) => theme.colors.wrapperTextColor};
  div > div > div * {
    color: ${({ theme }) => theme.colors.wrapperTextColor};
  }
  @media (max-height: 900px) {
    top: -120px;
  }

  @media (max-height: 667px) {
    top: -80px;
  }
  img {
    border-radius: 7px;
    max-height: 350px;
    display: block;
    margin: 0 auto;
  }

  div > div > div a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.aColor};
  }
`

export default function Template({ data, className, location, theme }) {
  const state = useContext(GlobalStateContext)
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const SEOtitle = frontmatter.title
  const { keywords } = frontmatter.keywords
  const SEOImage = frontmatter.screenshot

  return (
    <>
      <SEO
        image={frontmatter.screenshot}
        title={SEOtitle}
        pathname={location.pathname}
        desc={frontmatter.description}
        node={markdownRemark}
        article
      />
      <Layout location={location.pathname} language={state.language}>
        <Hero heroImg={siteConfig.siteCover} title={frontmatter.title} />
        <StyledWrapper className={className}>
          <Container className="page-content" fluid>
            <div className="blog-post">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </Container>
        </StyledWrapper>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        Language
        keywords
        screenshot
      }
    }
  }
`
